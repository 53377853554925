@import 'ngx-toastr/toastr';
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Plus Jakarta Sans", "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.btn-plain {
  @apply flex items-center justify-center w-fit px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-md gap-x-2   hover:bg-gray-100 shadow-sm;
}

.btn-secondary {
  @apply flex items-center justify-center w-fit px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-secondary-500 rounded-md shrink-0 gap-x-2 hover:bg-secondary-600  shadow-sm;
}

/* sortable header directive */
th[sortable] {
  @apply cursor-pointer select-none;
}

th[sortable].desc:before,
th[sortable].asc:before {
  content: "";
  display: block;
  background: url("data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyBmaWxsPSIjN2Q3ZDdkIiB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBzdHJva2U9IiM3ZDdkN2QiPgoNPGcgaWQ9IlNWR1JlcG9fYmdDYXJyaWVyIiBzdHJva2Utd2lkdGg9IjAiLz4KDTxnIGlkPSJTVkdSZXBvX3RyYWNlckNhcnJpZXIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgoNPGcgaWQ9IlNWR1JlcG9faWNvbkNhcnJpZXIiPgoNPHBhdGggZD0iTTExLjE3OCAxOS41NjlhLjk5OC45OTggMCAwIDAgMS42NDQgMGw5LTEzQS45OTkuOTk5IDAgMCAwIDIxIDVIM2ExLjAwMiAxLjAwMiAwIDAgMC0uODIyIDEuNTY5bDkgMTN6Ii8+Cg08L2c+Cg08L3N2Zz4=")
    no-repeat;
  background-size: 15px;
  width: 15px;
  height: 15px;
  float: left;
  margin-left: -10px;
  margin-top: 2px;
}

th[sortable].desc:before {
  @apply rotate-180;
}

.input-plain {
  @apply py-1.5 px-5 text-gray-700 bg-white rounded-md placeholder-gray-400/70 ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-secondary-500;
}

.toast-container .ngx-toastr {
  @apply w-full md:w-fit shadow-md hover:shadow-lg;

  padding: 1rem 2rem !important;

  &.yd-toast-error {
    @apply bg-red-50 border border-red-200 border-r-4 border-r-[#F64B3C];
  }

  &.yd-toast-info {
    @apply bg-secondary-50 border border-secondary-200 border-r-4 border-r-secondary-500;
  }

  &.yd-toast-success {
    @apply bg-green-50 border border-green-200 border-r-4 border-r-[#76BF4C];
  }

  &.yd-toast-warning {
    @apply bg-yellow-50 border border-yellow-200 border-r-4 border-r-[#FFB446];
  }


  & .toast-title {
    @apply text-sm text-gray-800;
  }

  & .toast-message {
    @apply text-sm text-gray-600;
  }
}


